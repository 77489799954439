<template>
    <div class="courseDetails">
        <div class="c_D_back">
            <div class="plate-center">
                <div class="c_D_back_title">
                    <p>
                        <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }">
                                <span class="yh-fcF">首页</span>
                            </el-breadcrumb-item>
                            <el-breadcrumb-item :to="{ path: '/Intangible?generalBranch=1' }">
                                <span class="yh-fcF">工匠精神</span>
                            </el-breadcrumb-item>
                            <el-breadcrumb-item>
                                <span class="yh-fcF">详情</span>
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </p>
                </div>
                <div class="c_D_back_video">
                    <!-- <video ref="refVideo" id="picture" autoplay class="video"></video> -->
                    <video
                        id="videos"
                        :src="info.video"
                        class="yh-w100"
                        controls
                        preload
                        :poster="info.poster"
                        controlslist="nodownload"
                    ></video>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="plate-center">
            <div>
                <h3 class="yh-lh64">{{info.name || ''}}</h3>
                <div class="flex flex_between yh-fc666">
                    <div class="flex">时间： {{info.create_time}}</div>
                    <div>{{info.view || 0}} 人阅读</div>
                </div>
            </div>

            <div class="yh-border1 yh-mt30">
                <div class="yh-h72 yh-fc-blue yh-ptb0lr20 yh-fw600 level_align yh-fc333 yh-fz20">
                    <div class="level_align yh-h100 yh-cp">
                        <span>简介</span>
                    </div>
                </div>

                <!-- 简介 -->
                <div class="yh-pd20" v-html="parseRichImgStyle(info.detail)"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "artisan",
    data() {
        return {
            classify: 1, // 0 课程目录 1简介
            playing: false, // 播放中
            id: "", // 工匠详情id
            info: {}, // 课程详情
        };
    },
    created() {
        this.id = this.$route.query.id;
    },
    mounted() {
        this.getInfo();
    },
    methods: {
        // 获取课程详情
        getInfo() {
            this.$axios
                .post(this.$api.artisanDetail, { id: this.id })
                .then((res) => {
                    if (res.code === 1) {
                        this.info = res.data;
                        // let video = document.getElementById("videos");
                        // this.$nextTick(() => {
                        //     this.$forceUpdate();
                        //     video.play();
                        // });
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.courseDetails {
    margin-bottom: 60px;
}
.c_D_back {
    background-color: #2f2f2f;
    .c_D_back_title {
        padding: 20px 0;
        p {
            color: #ffffff;
            font-size: 16px;
            font-family: "MicrosoftYaHei";
            border-left: 3px solid #ffffff;
            padding-left: 10px;
        }
    }
}
.c_D_back_video {
    .c_D_video_con {
        height: 51px;
        background-color: #000000;
        display: flex;
        align-items: center;
    }
}
.classifyAct {
    font-weight: bold;
    color: #1678ff;
    span {
        line-height: 32px;
        border-bottom: 2px solid #1678ff;
    }
}
/deep/ .el-collapse-item__header {
    background: #f2f2f2;
    margin-bottom: 10px;
}
/deep/ .el-collapse-item__content {
    padding-bottom: 10px;
}

.actChapter /deep/ .el-collapse-item__header {
    background: rgba(22, 120, 255, 0.2);
}
</style>
